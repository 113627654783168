$gradation: linear-gradient(
  to right,
  #fbfbfb,
  #f6f6f6,
  #f1f1f1,
  #eeeeee,
  #bebebe,
  #777777,
);

$gradation-sphere: radial-gradient(
  circle at 10px 10px,
  #fbfbfb,
  #f6f6f6,
  #f1f1f1,
  #eeeeee,
  #bebebe,
  #777777,
);

$gradation-top: linear-gradient(to right, #f3f3f3, #eeeeee, #a6a6a6);

$height: 40px;



.box {
  position: relative;
}
.sphere {
  position: absolute;
  top: -80%;
  left: 50%;
  transform: translate(-50%, -80%);
  width: 300px;
  height: 300px;
  background: $gradation-sphere;
  border-radius: 50%;
  animation: up-and-down 5s ease-in-out infinite alternate;

  &:before {
    content: "";
    position: absolute;
    background: radial-gradient(
      circle at 50% 120%,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0) 70%
    );
    border-radius: 50%;
    bottom: 2.5%;
    left: 5%;
    opacity: 0.6;
    height: 100%;
    width: 90%;
    filter: blur(5px);
    z-index: 2;
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 5%;
    left: 10%;
    border-radius: 50%;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.8) 14%,
      rgba(255, 255, 255, 0) 24%
    );
    transform: translateX(-80px) translateY(-90px) skewX(-20deg);
    filter: blur(15px);
  }
}

.cy {
  width: 300px;
  position: relative;
  top: 200%;
  left: 50%;
  transform: translate(-50%, 200%);

  &__cy {
    width: 100%;
    height: $height;

    border-radius: 100%;
  }

  .top {
    background: linear-gradient(to right, #f8f8f8 0 50%, #8e8e8e);
    position: relative;
    overflow: hidden;

    &::before {
      position: absolute;
      top: -80%;
      left: 50%;
      content: "";
      width: 200%;
      height: 150%;
      border-radius: 100%;
      background: rgba(30, 30, 30, 0.1);
      filter: blur(5px);
      z-index: 2;
      animation: small-shadow 5s ease-in-out infinite alternate;
    }
    &::after {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      content: "";
      width: 99%;
      height: 95%;
      border-radius: 100%;
      background: $gradation-top;
    }
  }

  .btm {
    background: $gradation;
    position: relative;

    &::before {
      position: absolute;
      top: 5%;
      left: 50%;
      transform: translate(-50%, 5%);
      content: "";
      width: 99%;
      height: 95%;
      border-radius: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: -2;
    }

    &::after {
      position: absolute;
      top: -20%;
      left: 50%;
      transform: translate(-50%, -20%);
      content: "";
      width: 80%;
      height: 160%;
      border-radius: 100%;
      box-shadow: 60px -10px 10px rgba(0, 0, 0, 0.2);
      z-index: -2;
    }
  }

  &__lec {
    position: absolute;
    transform: translateY(-50%);
    width: 100%;
    height: $height;
    background: $gradation;
    z-index: -1;
  }

  .sphare-shadow {
    position: absolute;
    top: -20%;
    left: 30%;
    width: 320px;
    height: 90px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.05);
    transform: skewX(10deg);
    filter: blur(5px);
    z-index: -2;
    animation: big-shadow 5s ease-in-out infinite alternate;
  }
}

@keyframes up-and-down {
  0% {
    transform: translate(-50%, -80%);
  }
  100% {
    transform: translate(-50%, -40%);
  }
}

@keyframes big-shadow {
  0% {
    top: -20%;
    left: 30%;
  }
  100% {
    top: 0%;
    left: 0%;
  }
}

@keyframes small-shadow {
  0% {
    top: -80%;
    left: 50%;
  }
  100% {
    top: -30%;
    left: 20%;
  }
}
