/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
    #navLogo {
        width: 100%;

    }
   }

   @media (min-width: 585px) { 
    #navLogo {
    }
   }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    #navLogo {
      width: 50%;
    }
  
   }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) { 
  
   }