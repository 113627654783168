/* rushy color gradient  background-image: linear-gradient(to right top, #c02425, #b27821, #a0ad64, #a8d4b7, #e0f2f1);  */

  
  .night-container
  {
    width: 100%;
    height: 400px;
    position: relative;
    background: linear-gradient(180deg, #004d40 23%, #166659 60%, #298073 77%, #3b9a8f 91%, #4db6ac 97%);
    box-shadow: 0 20px 30px rgba(0,0,0,.1), 0 30px 20px rgba(0,0,0,.1);
    border-radius: 25px;
    overflow: hidden;
    z-index:-10;
  }
  .svg {
    display: inline-block;
  }
  .landscape-1 {
    position: absolute;
    transform: translateY(200px) scale(2) rotate(5deg);
  }
  .landscape-2 {
    position: absolute;
    transform: translate(30px,260px) scale(1.6) rotate(3deg);
  }
  .landscape-3 {
    position: absolute;
    transform: translateY(280px) scale(2);
  }
  .landscape-4 {
    position: absolute;
    transform: translateY(300px);
  }
  .ice-line-1 {
    background:rgba(255, 255, 255, 0.5);
    width:900px;
    height: 20px;
    border-radius: 80% ;
    position:absolute;
    bottom: -155px;
    left: 30px;
    z-index:10;
    transform: scale(0.8) rotate(30deg);
    box-shadow:10px 0px 3px rgba(255, 255, 255, 1);
  }
  .ice-line-1 {
    background:rgba(255, 255, 255, 0.5);
    width:900px;
    height: 20px;
    border-radius: 80% ;
    position:absolute;
    bottom: -155px;
    left: 30px;
    z-index:11;
    transform: scale(0.8) rotate(30deg);
    box-shadow:10px 0px 3px rgba(255, 255, 255, 1);
  }
  .ice-line-2 {
    background:rgba(255, 255, 255, 0.5);
    width:900px;
    height: 20px;
    border-radius: 80% ;
    position:absolute;
    bottom: -70px;
    left: 50px;
    z-index:12;
    transform: scale(0.6) rotate(20deg);
    box-shadow:10px 10px 3px rgba(255, 255, 255, 1);
  }
  .pond-base {
    background: linear-gradient(180deg, rgba(206, 234, 246,1) 5%, rgba(122, 193, 228,1) 30%, rgba(104,21,162,1) 77%, rgba(137,44,220,1) 91%, rgba(188,111,241,1) 97%);
    width: 600px;
    height: 200px;
    border-radius: 50%;
    position:absolute;
    bottom: -160px;
    left:-140px;
    z-index:5;
  }
  .pond-ice {
    background: linear-gradient(180deg, rgba(255,255,255, 0.5) 5%, rgba(122, 193, 228,1) 30%, rgba(104,21,162,1) 77%, rgba(137,44,220,1) 91%, rgba(188,111,241,1) 97%);
    width: 600px;
    height: 200px;
    border-radius: 50%;
    position:absolute;
    bottom: -165px;
    left:-140px;
    z-index:5;
  }
  .ground-1 {
    background: linear-gradient(180deg, rgba(255,255,255,1) 10%, #4db6ac 60%, rgba(104,21,162,1) 77%, rgba(137,44,220,1) 91%, rgba(188,111,241,1) 97%);
    width: 200px;
    height: 90px;
    border-radius: 50%;
    position:absolute;
    bottom:-120px;
    left:80px;
    z-index:1;
  }
  .ground-2 {
    background: linear-gradient(180deg, rgba(255,255,255,1) 5%, #4db6ac 30%, rgba(104,21,162,1) 77%, rgba(137,44,220,1) 91%, rgba(188,111,241,1) 97%);
    width: 400px;
    height: 200px;
    border-radius: 50%;
    position:absolute;
    bottom: -230px;
    left:-150px;
    z-index:3;
  
  }
  .star1 {
    background: white;
    width: 3px;
    height: 3px;
    position: absolute;
    top: 20%;
    left: 10%;
    border-radius: 100%;
    opacity: 0.5;
    animation: glowing-stars 2s infinite alternate linear 250ms;
  }
  .star2 {
    background: white;
    width: 3px;
    height: 3px;
    position: absolute;
    top: 30%;
    left: 80%;
    border-radius: 100%;
    opacity: 0.5;
    animation: glowing-stars 2s infinite alternate linear 750ms;
  }
  .star3 {
    background: white;
    width: 3px;
    height: 3px;
    position: absolute;
    top: 10%;
    left: 80%;
    border-radius: 100%;
    opacity: 0.5;
    animation: glowing-stars 2s infinite alternate linear 900ms;
  }
  .star4 {
    background: white;
    width: 3px;
    height: 3px;
    position: absolute;
    top: 7%;
    left: 50%;
    border-radius: 100%;
    opacity: 0.5;
    animation: glowing-stars 2s infinite alternate linear 1s;
  }
  .star5 {
    background: white;
    width: 3px;
    height: 3px;
    position: absolute;
    top: 40%;
    left: 8%;
    border-radius: 100%;
    opacity: 0.5;
    animation: glowing-stars 2s infinite alternate linear 2s;
  }
  .star6 {
    background: white;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 4%;
    left: 28%;
    border-radius: 100%;
    opacity: 0.5;
    animation: glowing-stars 2s infinite alternate linear 500ms;
  }
  .star7 {
    background: white;
    width: 3px;
    height: 3px;
    position: absolute;
    top: 25%;
    left: 68%;
    border-radius: 100%;
    opacity: 0.5;
    animation: glowing-stars 2s infinite alternate linear 660ms;
  }
  .star8 {
    background: white;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 48%;
    border-radius: 100%;
    opacity: 0.5;
    animation-name: glowing-stars 2s infinite alternate linear;
  }
  .star9 {
    background: white;
    width: 4px;
    height: 4px;
    position: absolute;
    bottom: 2%;
    left:43%;
    border-radius: 100%;
    opacity: 0.25;
    animation: glowing-stars 2s infinite alternate linear 1500ms;
    z-index:15;
  }
  .star10 {
    background: white;
    width: 3px;
    height: 3px;
    position: absolute;
    bottom: 2%;
    left: 15%;
    border-radius: 100%;
    opacity: 0.25;
    animation: glowing-stars 2s infinite alternate linear 960ms;
    z-index:15;
  }
  .star11 {
    background: white;
    width: 4px;
    height: 4px;
    position: absolute;
    bottom: 5%;
    left: 28%;
    border-radius: 100%;
    opacity: 0.5;
    animation-name: glowing-stars 2s infinite alternate linear;
    z-index:16;
  }
  .mountain, .mountain-two, .mountain-three {
      position: absolute;
      bottom: 25px;
      right: 0;
      border-left: 150px solid transparent;
      border-right: 150px solid transparent;
      border-bottom: 180px solid #00838f ;
      z-index: 0;
  }
  .mountain-two {
      right: 80px;
      bottom: -20px;
      opacity: .3;
      z-index: -1;
  }
  .mountain-three {
      right: -60px;
      bottom:-10px;
      opacity: .5;
      z-index: -1;
  }
  .mountain-top {
      position: absolute;
      right: -65px;
      border-left: 65px solid transparent;
      border-right: 65px solid transparent;
      border-bottom: 77px solid #80deea;
      z-index: 2;
  }
   .mountain-cap-2 {
      position: absolute;
      top: 70px;
      height: 20px;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 25px solid #80deea;
  }
  .mountain-cap-1, .mountain-cap-3 {
      position: absolute;
      top: 70px;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-top: 25px solid #80deea;
  }
  .mountain-cap-1 { left: -55px; }
  .mountain-cap-2 { left: -25px; }
  .mountain-cap-3 { left: 5px; }
  
  @keyframes glowing-stars {
      0%   {opacity: 0;}
      50%  {opacity: 1;}
      100% {opacity: 0;}
  }
  
  .cloud-one {
    position: absolute;
    top: 10%;
    display: flex;
    perspective: 500px;
    height: 150px;
    width: 180px;
    z-index:-7;
    animation: swing1 30s linear infinite;
  }
  
  .cloud-two {
    position: absolute;
    top: 10%;
    display: flex;
    perspective: 500px;
    height: 150px;
    width: 180px;
    z-index:-7;
    animation: swing2 27s linear infinite;
  }
  .cloud-three {
    position: absolute;
    top: 10%;
    display: flex;
    perspective: 500px;
    height: 150px;
    width: 180px;
    z-index:5;
    animation: swing3 25s linear infinite;
  }
  .cloud-four {
    position: absolute;
    top: 10%;
    display: flex;
    perspective: 500px;
    height: 150px;
    width: 180px;
    z-index:5;
    animation: swing4 20s linear infinite;
  }
  .cloud-five {
    position: absolute;
    top: 10%;
    left: 20%;
    display: flex;
    perspective: 500px;
    height: 150px;
    width: 180px;
    z-index:-7;
    animation: swing5 42s linear infinite;
  }
  .moon{
                  position: absolute;
                  margin: auto;
                  top: 0;
                  right: 30%;
                  bottom: 0;
                  left: 0;
                  width: 75px;
                  height: 75px;
                  background-color: transparent;
                  border-radius: 50%;
                  box-shadow: -20px 10px 0px 0px #fff;
              }
        .moon2{
                        position: absolute;
                        margin: auto;
                opacity: 50%;
                        top: 0;
                        right: 30%;
                        bottom: 0;
                        left: 0;
                        width: 75px;
                        height: 75px;
                        background-color: transparent;
                        border-radius: 50%;
                        box-shadow: -20px 10px 3px 3px #fff;
                animation: glowing-stars 6s infinite alternate linear 1960ms;
                    }
  .tail {
    position:absolute;
    top:1.75em;
    left:0.75em;
    width: 110px;
    height: 15px;
    border-radius: 10px;
    background-color:#b2dfdb;
  }
  .tail2 {
    position:absolute;
    top:1.7em;
    left:1em;
    width: 100px;
    height: 20px;
    border-radius: 10px;
    background-color:#b2dfdb;
  }
  .bump1 {
    position:absolute;
    top:0.2em;
    left:4.75em;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:#b2dfdb;
  }
  .bump1-1 {
    position:absolute;
    top:0em;
    left:3.75em;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-color:#b2dfdb;
  }
  .bump2 {
    position:absolute;
    top:0.7em;
    left:3em;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color:#b2dfdb;
  }
  .bump2-2 {
    position:absolute;
    top:0.75em;
    left:2.5em;
    width: 37px;
    height: 35px;
    border-radius: 50%;
    background-color:#b2dfdb;
  }
  .bump3 {
    position:absolute;
    top:0.75em;
    left:6.5em;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color:#b2dfdb;
  }
  @keyframes swing1 {
    0% {
      transform:  translate(-150px,150px) rotate(0deg) scale(0.2);
    }
    20% {
      transform:  translate(-50px,150px) rotate(0deg) scale(0.2);
    }
    30% {
      transform:  translate(100px,150px) rotate(0deg) scale(0.2);
    }
    50% {
      transform:  translate(250px,150px) rotate(0deg) scale(0.2);
       }
      60% {
      transform:  translate(350px,150px) rotate(0deg) scale(0.2);
    }
    80% {
      transform:  translate(400px,150px) rotate(0deg) scale(0.2);
    }
    90% {
      transform:  translate(450px,150px) rotate(0deg) scale(0.2);
    }
    100% {
      transform:  translate(550px,150px) rotate(0deg) scale(0.2);
    }
  }
  
  @keyframes swing2 {
  0% {
      transform:  translate(-150px,170px) rotate(0deg) scale(0.1);
    }
    20% {
      transform:  translate(100px,170px) rotate(0deg) scale(0.1);
    }
    30% {
      transform:  translate(200px,170px) rotate(0deg) scale(0.1);
    }
    50% {
      transform:  translate(300px,170px) rotate(0deg) scale(0.1);
       }
      60% {
      transform:  translate(400px,170px) rotate(0deg) scale(0.1);
    }
    80% {
      transform:  translate(500px,170px) rotate(0deg) scale(0.1);
    }
    90% {
      transform:  translate(600px,170px) rotate(0deg) scale(0.1);
    }
    100% {
      transform:  translate(700px,170px) rotate(0deg) scale(0.1);
    }
  }
  @keyframes swing3 {
    0% {
      transform:  translate(-150px,130px) rotate(0deg) scale(0.2);
    }
    20% {
      transform:  translate(10px,130px) rotate(0deg) scale(0.2);
    }
    30% {
      transform:  translate(110px,130px) rotate(0deg) scale(0.2);
    }
    50% {
      transform:  translate(210px,130px) rotate(0deg) scale(0.2);
       }
      60% {
      transform:  translate(310px,130px) rotate(0deg) scale(0.2);
    }
    80% {
      transform:  translate(410px,130px) rotate(0deg) scale(0.2);
    }
    90% {
      transform:  translate(510px,130px) rotate(0deg) scale(0.2);
    }
    100% {
      transform:  translate(610px,130px) rotate(0deg) scale(0.2);
    }
  }
  @keyframes swing4 {
    0% {
      transform:  translate(-150px,100px) rotate(0deg) scale(0.3);
    }
    20% {
      transform:  translate(0px,100px) rotate(0deg) scale(0.3);
    }
    30% {
      transform:  translate(80px,100px) rotate(0deg) scale(0.3);
    }
    50% {
      transform:  translate(180px,100px) rotate(0deg) scale(0.3);
      }
      60% {
      transform:  translate(280px,100px) rotate(0deg) scale(0.3);
    }
    80% {
      transform:  translate(380px,100px) rotate(0deg) scale(0.3);
    }
    90% {
      transform:  translate(480px,100px) rotate(0deg) scale(0.3);
    }
    100% {
      transform:  translate(580px,100px) rotate(0deg) scale(0.3);
    }
  }
  @keyframes swing5 {
    0% {
      transform:  translate(-150px, 190px) rotate(0deg) scale(0.1);
    }
    20% {
      transform:  translate(130px, 190px) rotate(0deg) scale(0.1);
    }
    30% {
      transform:  translate(290px, 190px) rotate(0deg) scale(0.1);
    }
    50% {
      transform:  translate(380px, 190px) rotate(0deg) scale(0.1);
       }
      60% {
      transform:  translate(450px, 190px) rotate(0deg) scale(0.1);
    }
    80% {
      transform:  translate(500px, 190px) rotate(0deg) scale(0.1);
    }
    90% {
      transform:  translate(680px, 190px) rotate(0deg) scale(0.1);
    }
    100% {
      transform:  translate(920px,190px) rotate(0deg) scale(0.1);
    }
  }
  